import { get as _get, capitalize as _capitalize, isEmpty } from 'lodash';
import sanitizeHtml from 'sanitize-html';

export const dunsNumberRegex = /^[0-9]{9}$/;
export const cnpjRegex = /^[0-9]{14}$/;
export const cpfRegex = /^[0-9]{11}$/;
export const rucRegex = /^[0-9]{11}$/;

const ignoreCharactersRegex = /\.|-|\//gi;

// split by >1 combination of empty space, comma or semicolon
export const splitByDelimiters = (text = '') =>
  text
    .toString()
    .trim()
    .split(/[\s,;]+/);

export const parseDunsNumbers = (text = '') => {
  // we want to ignore characters: [- , /] in duns number
  const numbers = splitByDelimiters(text).map((txt) => txt.replace(ignoreCharactersRegex, ''));
  const allNumbersMatchDunsRegex = numbers.every((p) => p.match(dunsNumberRegex));

  if (allNumbersMatchDunsRegex) {
    return numbers;
  }

  return null;
};

export const parseDunsNumber = (text = '') => {
  const duns = text.replace(ignoreCharactersRegex, '');
  return !!duns.match(dunsNumberRegex) && duns;
};

// CNPJ, CPF, RUC
export const parseTaxIds = (text = '') => {
  const numbers = splitByDelimiters(text).map((txt) => txt.replace(ignoreCharactersRegex, ''));
  const taxIdFormatsToCheck = [cnpjRegex, cpfRegex, rucRegex];

  for (let taxIdFormat of taxIdFormatsToCheck) {
    if (numbers.every((numString) => numString.match(taxIdFormat))) {
      return numbers;
    }
  }

  return null;
};

export const titleCase = (string) => string.split(' ').map(_capitalize).join(' ');

export const formatNumber = (num, lang = 'en', amount = false) => {
  if (!num || isNaN(num)) {
    return num;
  }

  // map of decimal point delimiters to remove when not amount
  const amountDelimiter = {
    en: '.',
    es: ',',
  };

  // if string, convert it to number
  num = +num;

  let parsed = '';

  switch (lang) {
    case 'en':
      parsed = num.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
      break;
    case 'es':
      parsed = num
        .toFixed(3)
        .replace(/\d(?=(\d{3})+\.)/g, '$&.')
        .replace(/.([^.]*)$/, ',$1');
      break;
    default:
      parsed = num.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
  }

  if (!amount) {
    const delimiter = _get(amountDelimiter, lang, '.');
    return parsed.substring(0, parsed.indexOf(delimiter));
  }

  return parsed;
};

export const getTextFromHtml = (html) => {
  if (isEmpty(html)) return null;

  // Cleans up some weird stuff
  const cleanedHtml = html.replace(/&nbsp;/g, '').replace(/(<br.+?>)+/g, ' ');

  const options = {
    allowedTags: [],
    nonTextTags: [
      'style',
      'script',
      'textarea',
      'option',
      'noscript',
      'h1',
      'h2',
      'h3',
      'h4',
      'h5',
      'h6',
      'ul',
      'ol',
      'li',
    ],
  };

  const textFromHtml = sanitizeHtml(cleanedHtml, options);

  return textFromHtml;
};

export const httpsURL = (url) => {
  if (!/^https?:\/\//i.test(url)) {
    return `https://${url}`;
  }

  return url;
};
