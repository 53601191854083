import React, { useContext, ReactElement, MouseEvent } from 'react';

import classNames from 'classnames';
import NextLink from 'next/link';

import { RouterContext } from 'components/_context/RouterContext';
import { httpsURL } from 'utils/formatString';
import { linkVariants } from 'utils/variants';

type LinkVariant = (typeof linkVariants)[number];

interface LinkProps {
  children?: React.ReactNode;
  className?: string;
  variant?: LinkVariant;
  href: string;
  externalLink?: boolean;
  linkContent?: ReactElement;
  locale?: string | boolean;
  fullReload?: boolean;
  onClick?: (event: MouseEvent<HTMLAnchorElement>) => void;
  shallow?: boolean;
}

const Link = ({
  children,
  variant,
  className,
  href,
  externalLink,
  linkContent,
  locale,
  fullReload,
  onClick,
  shallow,
  ...rest
}: LinkProps) => {
  const { getLocalizedPath } = useContext(RouterContext);

  const linkClassName = classNames([{ [`text-${variant}`]: variant, [className!]: className !== undefined }]);

  const hrefLink = getLocalizedPath(href, locale);

  const handleOnClick = (e) => {
    if (onClick) {
      onClick(e);
    }

    if (fullReload) {
      e.preventDefault();
      window.location = e.currentTarget.getAttribute('href');
    }
  };

  return (
    <NextLink
      shallow={shallow}
      legacyBehavior
      href={externalLink ? httpsURL(href) : hrefLink}
      locale={false}
      passHref
      {...rest}
    >
      {linkContent ? (
        React.cloneElement(linkContent, {
          target: externalLink ? '_blank' : undefined,
          rel: externalLink ? 'noreferrer' : undefined,
          onClick: fullReload ? handleOnClick : undefined,
        })
      ) : (
        /* eslint-disable
          jsx-a11y/anchor-is-valid,
          jsx-a11y/click-events-have-key-events,
          jsx-a11y/no-static-element-interactions
        */
        <a
          className={linkClassName}
          target={externalLink ? '_blank' : undefined}
          rel={externalLink ? 'noreferrer' : undefined}
          onClick={fullReload ? handleOnClick : onClick}
        >
          {children}
        </a>
        /* eslint-enable */
      )}
    </NextLink>
  );
};

export default Link;
