import React from 'react';

import classNames from 'classnames';

import { Spinner } from '_ui/_common/Button/_components/Spinner';
import { ButtonProps, VariantEnum, ThemeEnum, ShapeEnum, SizeEnum } from '_ui/_common/Button/interfaces';
import { useButtonGeneratedClasses } from '_ui/_common/Button/theme/hooks';

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children = undefined,
      variant = 'contained',
      theme = 'primary',
      shape = 'default',
      type = 'button',
      size = 'base',
      loading = false,
      disabled = false,
      tabIndex = undefined,
      contentPosition = 'center',
      onClick,
    }: ButtonProps,
    ref,
  ): React.JSX.Element => {
    const { buttonClasses, spinnerClasses } = useButtonGeneratedClasses({
      variant: VariantEnum[variant.toUpperCase()],
      theme: ThemeEnum[theme.toUpperCase()],
      shape: ShapeEnum[shape.toUpperCase()],
      size: SizeEnum[size.toUpperCase()],
      disabled,
      loading,
    });

    return (
      <div className="relative">
        <button
          ref={ref}
          type={type}
          onClick={onClick}
          tabIndex={tabIndex}
          disabled={disabled}
          className={buttonClasses}
        >
          <div className={classNames(`flex justify-${contentPosition} items-center w-full`, { 'opacity-0': loading })}>
            {children}
          </div>
        </button>
        {loading && (
          <Spinner size={size} className={classNames(spinnerClasses, `p-0 m-auto top-0 right-0 left-0 bottom-0`)} />
        )}
      </div>
    );
  },
);

export { Button };
