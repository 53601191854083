import React from 'react';

import classNames from 'classnames';

import { Spinner } from '_ui/_common/Button/_components/Spinner';
import { LinkButtonProps, ShapeEnum, SizeEnum, ThemeEnum, VariantEnum } from '_ui/_common/Button/interfaces';
import { useButtonGeneratedClasses } from '_ui/_common/Button/theme/hooks';
import Link from 'components/Link';

const LinkButton = ({
  children = undefined,
  variant = 'contained',
  theme = 'primary',
  shape = 'default',
  size = 'base',
  loading = false,
  disabled = false,
  onClick,
  href,
  fullReload = false,
  externalLink = false,
  shallow,
}: LinkButtonProps): React.JSX.Element => {
  const { buttonClasses, spinnerClasses } = useButtonGeneratedClasses({
    variant: VariantEnum[variant.toUpperCase()],
    theme: ThemeEnum[theme.toUpperCase()],
    shape: ShapeEnum[shape.toUpperCase()],
    size: SizeEnum[size.toUpperCase()],
    disabled,
    loading,
  });

  return (
    <Link
      href={href}
      onClick={onClick}
      fullReload={fullReload}
      className={buttonClasses}
      externalLink={externalLink}
      shallow={shallow}
    >
      {loading && <Spinner size={size} className={spinnerClasses} />}

      <div className={classNames('flex justify-center items-center w-full', { 'opacity-0': loading })}>{children}</div>
    </Link>
  );
};

export { LinkButton };
