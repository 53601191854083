import { ThemeDefinition } from '_ui/_common/Button/theme/interfaces';

const PRIMARY: ThemeDefinition = {
  contained: {
    text: { default: 'white', hover: 'white' },
    background: { default: 'blue-600', hover: 'blue-700' },
    border: { default: 'none', hover: 'none' },
    ring: {
      focus: {
        ringWidth: 2,
        ringColor: 'indigo-500',
        ringOffsetWidth: 2,
        ringOffsetColor: 'white',
      },
    },
    shadow: { default: 'sm' },
    opacity: 'opacity-30',
    spinColor: 'white',
  },
  outlined: {
    text: { default: 'blue-600', hover: 'blue-600' },
    background: { default: 'white', hover: 'blue-50' },
    border: { default: 'blue-500', hover: 'blue-500' },
    ring: {
      focus: {
        ringWidth: 2,
        ringColor: 'indigo-500',
        ringOffsetWidth: 2,
        ringOffsetColor: 'white',
      },
    },
    shadow: { default: 'sm' },
    opacity: 'opacity-30',
    spinColor: 'blue-600',
  },
  text: {
    text: { default: 'blue-700', hover: 'blue-700' },
    background: { default: 'transparent', hover: 'blue-50', focus: 'blue-50' },
    border: { default: 'none', hover: 'none' },
    shadow: { default: 'none' },
    opacity: 'opacity-30',
    spinColor: 'blue-600',
  },
};

const SECONDARY: ThemeDefinition = {
  contained: {
    text: { default: 'blue-700', hover: 'blue-700' },
    background: { default: 'blue-100', hover: 'blue-200' },
    border: { default: 'none', hover: 'none' },
    ring: {
      focus: {
        ringWidth: 2,
        ringColor: 'indigo-500',
        ringOffsetWidth: 2,
        ringOffsetColor: 'white',
      },
    },
    shadow: { default: 'sm' },
    opacity: 'opacity-30',
    spinColor: 'blue-500',
  },
  outlined: {
    text: { default: 'gray-700', hover: 'gray-700' },
    background: { default: 'white', hover: 'gray-50' },
    border: { default: 'gray-300', hover: 'gray-300' },
    ring: {
      focus: {
        ringWidth: 2,
        ringColor: 'indigo-500',
        ringOffsetWidth: 2,
        ringOffsetColor: 'white',
      },
    },
    shadow: { default: 'sm' },
    opacity: 'opacity-30',
    spinColor: 'blue-500',
  },
  text: {
    text: { default: 'gray-700', hover: 'gray-700' },
    background: { default: 'transparent', hover: 'gray-50', focus: 'gray-50' },
    border: { default: 'none', hover: 'none' },
    shadow: { default: 'none' },
    opacity: 'opacity-30',
    spinColor: 'blue-500',
  },
};

const DARK: ThemeDefinition = {
  contained: {
    text: { default: 'white', hover: 'white' },
    background: { default: 'blue-600', hover: 'blue-700' },
    border: { default: 'none', hover: 'none' },
    ring: {
      focus: {
        ringWidth: 2,
        ringColor: 'white',
        ringOffsetWidth: 2,
        ringOffsetColor: 'indigo-500',
      },
    },
    shadow: { default: 'sm' },
    opacity: 'opacity-30',
    spinColor: 'white',
  },
  outlined: {
    text: { default: 'gray-700', hover: 'gray-700' },
    background: { default: 'white', hover: 'gray-50' },
    border: { default: 'gray-300', hover: 'gray-300' },
    ring: {
      focus: {
        ringWidth: 2,
        ringColor: 'white',
        ringOffsetWidth: 2,
        ringOffsetColor: 'indigo-500',
      },
    },
    shadow: { default: 'sm' },
    opacity: 'opacity-30',
    spinColor: 'blue-500',
  },
  text: {
    text: { default: 'white', hover: 'white' },
    background: { default: 'transparent', hover: 'transparent/10', focus: 'transparent/10' },
    border: { default: 'none', hover: 'none' },
    shadow: { default: 'none' },
    opacity: 'opacity-30',
    spinColor: 'white',
  },
};

const PREMIUM: ThemeDefinition = {
  contained: {
    text: { default: 'white', hover: 'white' },
    background: { default: 'orange-600', hover: 'orange-700' },
    border: { default: 'none', hover: 'none' },
    ring: {
      focus: {
        ringWidth: 2,
        ringColor: 'orange-400',
        ringOffsetWidth: 2,
        ringOffsetColor: 'white',
      },
    },
    shadow: { default: 'sm' },
    opacity: 'opacity-30',
    spinColor: 'white',
  },
  outlined: {
    text: { default: 'orange-700', hover: 'orange-700' },
    background: { default: 'white', hover: 'orange-50' },
    border: { default: 'orange-600', hover: 'orange-600' },
    ring: {
      focus: {
        ringWidth: 2,
        ringColor: 'orange-400',
        ringOffsetWidth: 2,
        ringOffsetColor: 'white',
      },
    },
    shadow: { default: 'sm' },
    opacity: 'opacity-30',
    spinColor: 'blue-500',
  },
  text: {
    text: { default: 'orange-700', hover: 'orange-700' },
    background: { default: 'transparent', hover: 'orange-50', focus: 'orange-50' },
    border: { default: 'none', hover: 'none' },
    shadow: { default: 'none' },
    opacity: 'opacity-30',
    spinColor: 'blue-500',
  },
};

const WARNING: ThemeDefinition = {
  contained: {
    text: { default: 'white', hover: 'white' },
    background: { default: 'red-600', hover: 'red-700' },
    border: { default: 'none', hover: 'none' },
    ring: {
      focus: {
        ringWidth: 2,
        ringColor: 'red-500',
        ringOffsetWidth: 2,
        ringOffsetColor: 'white',
      },
    },
    shadow: { default: 'sm' },
    opacity: 'opacity-30',
    spinColor: 'white',
  },
  outlined: {
    text: { default: 'red-600', hover: 'red-600' },
    background: { default: 'white', hover: 'red-50' },
    border: { default: 'red-600', hover: 'red-600' },
    ring: {
      focus: {
        ringWidth: 2,
        ringColor: 'red-500',
        ringOffsetWidth: 2,
        ringOffsetColor: 'white',
      },
    },
    shadow: { default: 'sm' },
    opacity: 'opacity-30',
    spinColor: 'red-600',
  },
  text: {
    text: { default: 'red-600', hover: 'red-600' },
    background: { default: 'transparent', hover: 'red-50', focus: 'red-50' },
    border: { default: 'none', hover: 'none' },
    shadow: { default: 'none' },
    opacity: 'opacity-30',
    spinColor: 'red-600',
  },
};

export { PRIMARY, SECONDARY, DARK, PREMIUM, WARNING };
