import React from 'react';

import classNames from 'classnames';

import { SizeType } from '_ui/_common/Button/interfaces';

const spinSize = {
  xs: 'h-4',
  sm: 'h-4',
  base: 'h-5',
  lg: 'h-5',
  xl: 'h-6',
  full: 'h-5',
};

interface Props {
  className: string;
  size?: SizeType;
}

const Spinner = ({ className, size = 'base' }: Props): React.JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    className={classNames(`absolute mx-auto ${spinSize[size]} ${className} animate-spin`)}
  >
    <circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" className="opacity-25" />
    <path
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    />
  </svg>
);

export { Spinner };
